import React, { useEffect, useState } from 'react';
import './styles.scss'
type PROPS_TYPE = {
    onClose: () => void; show: boolean; width: string | number;
    className?:string;
}

function stopClickingThrough(e: React.MouseEvent) {
    //stopping the inner elements' onClick from being triggered if an overlapping element is clicked
    //this is done to ensure that the modal closes when clicked on the empty space but stopping the propagation and doing nothing when clicking on the modal body
    e.stopPropagation();
}
export default function Modal(props: React.PropsWithChildren<PROPS_TYPE>) {
    const [show, setShow] = useState(props.show); //visibility of modal
    useEffect(() => {
        if (!props.show) //we want to wait 350ms for the closing animation to be completed before turning off the display
            setTimeout(() => setShow(false), 350)
        else //no wait needed on opening
            setShow(true)
    }, [props.show])
    if (!show) return null; //no display if modal closed

    //modal open
    return <div onClick={props.onClose} className={'modal'}>
        {props.show&&<div tabIndex={0} onClick={stopClickingThrough}
            className={'sub-modal'}>
            {props.children}
        </div>}
    </div>
}
