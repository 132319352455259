import React from "react";
import './styles.scss'
import {StaticImage} from "gatsby-plugin-image";
import {LocaleType} from "../../../interfaces/Locale";
import svg1 from '../../../images/1.svg'
import svg3 from '../../../images/3.svg'
export default function Tests({locale}:{locale:LocaleType}) {
    return <div className="test-container">
        <div className="head">
            <div className="title">
                {locale['Home.Tests.title']}
            </div>
            <div className="underline"/>
            <div className="text-container">
                <img className={'image'} placeholder="blurred" src={svg1} alt="test1"/>


                <div className="text">
                    {locale['Home.Tests.desc1']}
                </div>
            </div>
            <div className="spiral">
                <StaticImage placeholder="blurred" src="../../../images/spiral.png" alt="spiral"/>
            </div>
        </div>
        <div className="elements">
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.element1.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.element1.desc1']}
                    </div>
                </div>
                <div className="image-container">
                    <StaticImage className={'image'} placeholder="blurred" src="../../../images/2.gif" alt="spiral"/>

                </div>
            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.element2.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.element2.desc1']}
                        </div>
                </div>
                <div className="image-container">
                    <img className={'image'} placeholder="blurred" src={svg3} alt="test1"/>

                </div>
            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.element3.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.element3.desc1']}</div>
                </div>
                <div className="image-container">
                    <StaticImage  placeholder="blurred" src="../../../images/4.png" alt="test1"/>

                </div>
            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.element4.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.element4.desc1']}</div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/5.png" alt="test1"/>
                </div>
            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.element5.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.element5.desc1']}</div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/6.png" alt="test1"/>
                </div>
            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.element6.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.element6.desc1']}</div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/7.jpg" alt="test1"/>
                </div>
            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.element7.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.element7.desc1']}</div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/8.jpg" alt="test1"/>
                </div>
            </div>
            <div className="element">
                <div className="content">
                    <div className="title">{locale['Home.Tests.element8.title']}</div>
                    <div className="underline"/>
                    <div className="text">
                        {locale['Home.Tests.element8.desc1']}</div>
                </div>
                <div className="image-container">
                    <StaticImage placeholder="blurred" src="../../../images/9.jpg" alt="test1"/>
                </div>
            </div>
        </div>
        <div className="pattern">
            <StaticImage placeholder="blurred" src="../../../images/dot_pattern3.png" alt="pattern"/>
        </div>
    </div>
}
